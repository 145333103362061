import ProductItem from '../ProductItem/ProductItem'
import products from './products'
import './ProductList.css'

const ProductList = () => {
  return (
    <div className='ProductList'>
      {products.map((product, i) => <ProductItem key={i} {...product} />)}
    </div>
  )
}

export default ProductList
