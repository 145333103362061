import './ProductItem.css'

const ProductItem = ({ title, type, year, image, links }) => {
  return (
    <div className='ProductItem'>
      <div className='Cover'>
        <img src={image} alt={title} />
      </div>
      <div className='Meta'>
        <div>
          <h2 className='Title'>
            {title}
          </h2>
          {type
            ? <h3 className='Type'>
              {type}
            </h3>
            : null
          }
          <h3 className='Year'>
            {year}
          </h3>
        </div>
        <ul className='Links'>
          {Object.values(links).map((url, i, arr) =>
            <li key={i}>
              &#10132;&nbsp;
              <a href={url} target='_blank' rel='noreferrer'>
                {Object.keys(links)[i]}
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default ProductItem
