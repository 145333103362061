import timeToGoBackHome from './sfr_timetogobackhome_cover.png'
import rideFree from './sfr_ridefree_cover.jpg'
import itIsntNice from './sfr_isntnice_cover.jpg'
import cannonBall from './sfr_cannonball_cover.jpg'
import handsTogether from './sfr_hands_cover.jpg'
import songbook from './sfr_songbook_cover.jpg'
import noSeparation from './sfr_nosep_cover.jpg'
import sfrEp from './sfr_ep_cover.jpg'
import harvestFest from './sfr_harvestfest_cover.jpg'

export const types = {
  SINGLE: 'Single',
  ALBUM: 'Album',
  EP: 'EP'
}

const { SINGLE, ALBUM, EP } = types

export const products = [
  {
    title: 'Time To Go Back Home',
    type: SINGLE,
    year: 2022,
    image: timeToGoBackHome,
    links: {
      Stream: 'https://open.spotify.com/album/3AayR864rOjKqZCOPBH9vt?si=N093VaVzQVaRWDMlLKScuQ'
    }
  },
  {
    title: 'Ride Free',
    type: ALBUM,
    year: 2019,
    image: rideFree,
    links: {
      Stream: 'https://open.spotify.com/album/5wnfXUQar4Q0JcKZTtj5PB?si=Z4pcT1XnStyugnaa-qxi0A',
      Order: 'https://spiritfamilyreunion.bandcamp.com/album/ride-free',
      Watch: 'https://www.youtube.com/watch?v=HpDTzsSZnm0'
    }
  },
  {
    title: 'It Isn\'t Nice',
    type: SINGLE,
    year: 2018,
    image: itIsntNice,
    links: {
      Stream: 'https://open.spotify.com/album/6YOI2bzqFD9jh40zpuc1wL?si=jUcvmIF3SxeIGzEjHnXpOw',
      Order: 'https://spiritfamilyreunion.bandcamp.com/track/it-isnt-nice',
      Watch: 'https://www.youtube.com/watch?v=QgAo7IxAr_U'
    }
  },
  {
    title: 'Goin\' Out To Cannon Ball',
    type: SINGLE,
    year: 2016,
    image: cannonBall,
    links: {
      Stream: 'https://open.spotify.com/album/4c2Hr4pX8fbraGApcnLcka?si=Y1dRVcjCS1yC9Hr3fDjEeQ',
      Order: 'https://spiritfamilyreunion.bandcamp.com/track/goin-out-to-cannon-ball',
      Watch: 'https://www.youtube.com/watch?v=LROFxNXsqBE'
    }
  },
  {
    title: 'Harvest Festival Live',
    type: ALBUM,
    year: 2016,
    image: harvestFest,
    links: {
      Stream: 'https://open.spotify.com/album/2VDqFrfl4kWntnIHuRdCbM?si=rVvh4oCPT5uXr1DuWfbaEA',
      Order: 'https://spiritfamilyreunion.bandcamp.com/album/harvest-festival-live'
    }
  },
  {
    title: 'Hands Together',
    type: ALBUM,
    year: 2015,
    image: handsTogether,
    links: {
      Stream: 'https://open.spotify.com/album/0vDevof9Suej9iVEu8hRtf?si=YoOXNAs4R5WjCdJ9OgPpvg',
      Order: 'https://spiritfamilyreunion.bandcamp.com/album/hands-together',
      Watch: 'https://www.youtube.com/watch?v=NLgO8yrPgqo'
    }
  },
  {
    title: 'Songbook',
    year: 2014,
    image: songbook,
    links: {
      Order: 'https://spiritfamilyreunion.bandcamp.com/album/songbook',
    }
  },
  {
    title: 'No Separation',
    type: ALBUM,
    year: 2012,
    image: noSeparation,
    links: {
      Stream: 'https://open.spotify.com/album/1wacCiLWhtlt7gugSfdxk9?si=7NQUH2OORrek2gLAdYAKTQ',
      Order: 'https://spiritfamilyreunion.bandcamp.com/album/no-separation-3'
    }
  },
  {
    title: 'Spirit Family Reunion',
    type: EP,
    year: 2010,
    image: sfrEp,
    links: {
      Order: 'https://spiritfamilyreunion.bandcamp.com/album/spirit-family-reunion'
    }
  }
]

export default products
