import ProductList from '../ProductList/ProductList'
import logo from './sfr_logo.png'
import './App.css'

const App = () => {
  return (
    <div className='App'>
      <header className='Header'>
        <div className='Logo'>
          <img src={logo}  alt='Spirit Family Reunion' />
        </div>
        <p className='Description'>
          Spirit Family Reunion has shared their music directly with fans over the years, everywhere from living rooms and barns, to well-known festivals like Austin City Limits, Newport Folk, Hardly Strictly Bluegrass and Stagecoach.
        </p>
        <p className='BandLinks'>
          Get in touch via <a href='mailto:spiritfamilyreunionusa@gmail.com' target='_blank' rel='noreferrer'>email</a>, <a href='https://www.instagram.com/spiritfamilyreunion/' target='_blank' rel='noreferrer'>Instagram</a>, <a href='https://www.facebook.com/spiritfamilyreunion' target='_blank' rel='noreferrer'>Facebook</a>, <a href='https://twitter.com/spiritfam' target='_blank' rel='noreferrer'>Twitter</a>, <a href='https://open.spotify.com/artist/197bTSwvX2Rav8tkeZ3lQB?si=u535Sb1OSbuRlRd7NhKa2Q' target='_blank' rel='noreferrer'>Spotify</a>, and <a href='https://spiritfamilyreunion.bandcamp.com/' target='_blank' rel='noreferrer'>Bandcamp</a>.
        </p>
      </header>
      <main>
        <ProductList />
      </main>
    </div>
  )
}

export default App
